<template>
  <bryntum-gantt
    ref="gantt"
    v-bind="ganttConfig"
    :tasks="tasks"
    :dependencies="dependencies"
    :features="features"
    :resources="resources"
    :assignments="assignments"
  />
</template>

<script>
import { BryntumGantt, BryntumTaskEditor } from '@bryntum/gantt-vue'
import mixin from '@/components/InterfaceEditor/components/mixins'

import ruLocale from '@bryntum/gantt/locales/gantt.locale.Ru'
import engLocale from '@bryntum/gantt/locales/gantt.locale.En'
import { DateHelper, Fullscreen, AjaxHelper, Toast } from '@bryntum/gantt'
import FilterBuilder, { buildFilters, EComponentTypes } from '@/components/InterfaceEditor/components/utils'

import zipcelx from 'zipcelx'
import ActionExecutor from '@/core/infrastructure/service/ActionExecutor'
import refreshComponentsMixin from '@/components/InterfaceEditor/components/refreshComponentsMixin'
import RegistryCard from '@/components/RegistryCard/index.vue'
import { eventBus } from '@/eventBus'

let input, sendBtn

export default {
  name: 'a-gantt-bryntum',
  inject: {
    getParentDashboard: {
      default: () => {}
    },
    getViewer: {
      default: () => {
        return {}
      }
    },
    getParentContext: {
      default: () => {}
    },
    addMainTab: {
      default: () => {}
    },
    updateTab: {
      default: () => {}
    },
    tabs: {
      default: () => {}
    },
    activeTab: {
      default: () => {}
    },
    closeTab: {
      default: () => {}
    },
    openedCards: {
      default: () => {}
    },
    cancelChanges: {
      default: () => {}
    },
    openRegistryCard: {
      default: () => {}
    },
    openDashboardCard: {
      default: () => {}
    },
    openTabModalWindow: {
      default: () => {}
    },
    forceUpdateSettingsPanel: {
      default: () => () => {}
    },
    getContainersStore: {
      default: () => () => {}
    },
    getInterfaceWrapper: {
      default: () => () => {}
    },
    getEventBus: {
      default: () => () => {
        return {
          $emit: () => {},
          $on: () => {}
        }
      }
    }
  },
  mixins: [mixin, refreshComponentsMixin],
  components: {
    BryntumGantt,
    ruLocale,
    engLocale,
    DateHelper,
    AjaxHelper,
    Toast,
    BryntumTaskEditor,
    RegistryCard
  },

  props: {
    editorAlias: {
      type: String,
      description: 'alias'
    },
    config: {
      type: Object,
      editor: 'GanttBryntum',
      default: () => {
        return {
          taskSource: {
            entityType: null,
            entityId: null,
            planStartDate: null,
            planEndDate: null,
            factStartDate: null,
            factEndDate: null,
            keyField: null,
            nameField: null,
            parentIdField: null,
            percentDoneField: null,
            filters: [],
            isManuallyScheduledField: null,
            duration: null,
            effort: null,
            constraintType: null,
            constraintDate: null,
            inactive: null,
            hierarchyNumber: null,
            indexNumber: null,
            successors: null,
            predecessors: null,
            html: null,
            status: null
          },
          tableSource: {
            entityType: null,
            entityId: null,
            columns: []
          },
          tasksRelation: {
            entityType: null,
            entityId: null,
            keyField: null,
            fromAttr: null,
            toAttr: null,
            type: null
          },
          resources: {
            entityType: null,
            entityId: null,
            keyField: null,
            name: null,
            filters: [],
            imageUrl: null
          },
          resourceRelations: {
            entityType: null,
            entityId: null,
            keyField: null,
            taskId: null,
            resourceReferenceId: null,
            units: null
          },
          isDiagramEditing: true,
          defaultValuesForTask: [],
          chartItemAction: {
            card: {
              registryId: null
            }
          },
          isTaskTreeExpanded: false,
          isSaveGanttTasksWhenSavingCard: false,
          defaultColumns: [
            'rownumber',
            'wbs'
          ],
          defaultToolbarElements: [
            'addTaskButton',
            'undoRedo',
            'expandAllButton',
            'zoomInOut',
            'projectStartDateField',
            'importMPPFilePicker',
            'fullscreen'
          ]
        }
      }
    }
  },
  data () {
    return {
      gantt: null,
      ganttConfig: {
        height: '100%',
        tbar: {},
        selectionMode: {
          cell: true,
          dragSelect: true,
          rowNumber: true
        },
        taskStore: {
          tree: true,
          transformFlatData: true,
          wbsMode: 'auto'
        }
      },
      tasks: [],
      dependencies: [],
      // ругается, что нельзя юзать так и не реактивно (но по факту реактивно)
      features: {
        baselines: {
          disabled: true
        },
        progressLine: {
          disabled: true,
          statusDate: new Date()
        },
        parentArea: {
          disabled: true
        },
        labels: {
          disabled: true,
          left: {
            field: 'name'
          }
        },
        dependencyEdit: {
          disabled: false
        },
        mspExport: {
          filename: 'Gantt Export'
        },
        fillHandle: {
          disabled: false
        },
        excelExporter: {
          disabled: false,
          zipcelx,
          dateFormat: 'YYYY-MM-DD HH:mm'
        },
        taskEdit: {
          items: {
            generalTab: {
              items: {
                statusField: {
                  type: 'combo',
                  weight: 100,
                  label: 'Статус',
                  name: 'status',
                  items: null
                }
              }
            },
            notesTab: null,
            advancedTab: {
              items: {
                calendarField: false,
                schedulingModeField: false,
                effortDrivenField: false,
                divider: false,
                rollupField: false,
                inactiveField: false,
                ignoreResourceCalendarField: false
              }
            }
          }
        }
      },
      //
      revertedAttrsNameForColumn: [],
      revertedAttrsNameForDependencies: [],
      // костыли попросили для типов
      dependencyType: ['', 0, 1, 2, 3],
      constraintType: [
        '',
        'finishnoearlierthan',
        'finishnolaterthan',
        'mustfinishon',
        'muststarton',
        'startnoearlierthan',
        'startnolaterthan'
      ],
      dependenciesForDelete: [],
      tasksForDelete: [],
      localizedDependencyType: ['', 'НН', 'НО', 'ОН', 'ОО'],
      resources: [],
      assignments: [],
      assignmentsForDelete: [],
      missingParentIdRecords: []
    }
  },
  beforeMount () {
    if (this.config.hasOwnProperty('editorProperties')) {
      this.$set(this, 'config', this.config.editorProperties)
    }
  },
  async mounted () {
    this.gantt = await this.$refs.gantt.instance
    this.gantt.localeManager.locale = ruLocale
    this.gantt.accentProperties = this.config

    this.gantt.project.manuallyScheduled = true

    this.gantt.dependencyStore.on({
      remove: (event) => {
        event.records.map(record => {
          this.dependenciesForDelete.push(record)
        })
      }
    })

    this.gantt.taskStore.on({
      remove: (event) => {
        if (!event.isMove && !event.isCollapse) {
          event.records.map(record => {
            this.tasksForDelete.push(record)
          })
        }
      }
    })

    this.gantt.assignmentStore.on({
      remove: (event) => {
        event.records.map(record => {
          this.assignmentsForDelete.push(record)
        })
      }
    })

    if (this.config.taskSource.status) {
      await this.getStatusesByXrefValue()
    }

    if (typeof this.getEventBus() === 'object') {
      if (this.config.isSaveGanttTasksWhenSavingCard) {
        this.getEventBus().$on('registry-card-saved', this.saveTasksAndRecords)
      }
    }

    await this.addControlsOnToolbar()
    await this.addDefaultColumns()

    if (this.config.taskSource.entityId) {
      this.tasks = await this.getRowData(
        this.config.taskSource.entityId,
        this.config.taskSource.entityType
      )
    }

    if (this.config.tableSource.columns.length > 0) {
      await this.prepareColumnsFromSource(
        this.config.tableSource.columns
      )
    }

    if (this.config.tasksRelation.entityId) {
      await this.getTaskRelations(
        this.config.tasksRelation.entityId,
        'registry'
      )
    }

    if (this.config.resources.entityId) {
      this.resources = await this.getResources(
        this.config.resources.entityId,
        'registry',
        this.config.resources.filters
      )
      this.assignments = await this.getResources(
        this.config.resourceRelations.entityId,
        'registry',
        [],
        true
      )
    }

    if (this.config.isTaskTreeExpanded) {
      this.gantt.expandAll()
    }

    input = this.gantt.widgetMap.input
    sendBtn = this.gantt.widgetMap.sendBtn
  },
  beforeDestroy () {
    if (typeof this.getEventBus() === 'object') {
      this.getEventBus().$off('registry-card-saved', this.saveTasksAndRecords)
    }
  },
  methods: {
    async getRowData (entityId, sourceType) {
      let result
      let response
      let filters = this.config.taskSource.filters
      let buildFilters = this.getFilters(filters)

      switch (sourceType) {
        case 'query':
          response = await this.$http.post(
            `${this.$config.api}/datawarehouseservice/query/${entityId}`,
            typeof buildFilters.where !== 'undefined' ? buildFilters : null,
            { hideNotification: true }
          )
          result = this.prepareDataFromSource(response.data)
          break
        case 'registry':
          response = await this.$http.post(
            `${this.$config.api}/registryservice/registry/${entityId}`,
            typeof buildFilters.where !== 'undefined' ? buildFilters : null,
            { hideNotification: true }
          )
          result = this.prepareDataFromSource(response.data.data)
          break
      }

      return result
    },
    async prepareDataFromSource (data) {
      let keyField = this.config.taskSource.keyField
      let planStartDate = this.config.taskSource.planStartDate
      let planEndDate = this.config.taskSource.planEndDate
      let factStartDate = this.config.taskSource.factStartDate
      let factEndDate = this.config.taskSource.factEndDate
      let name = this.config.taskSource.nameField
      let parentId = this.config.taskSource.parentIdField
      let percentDone = this.config.taskSource.percentDoneField
      let isManuallyScheduled = this.config.taskSource.isManuallyScheduledField
      let duration = this.config.taskSource.duration
      let effort = this.config.taskSource.effort
      let constraintType = this.config.taskSource.constraintType
      let constraintDate = this.config.taskSource.constraintDate
      let inactive = this.config.taskSource.inactive
      let successors = this.config.taskSource.successors
      let predecessors = this.config.taskSource.predecessors
      let status = this.config.taskSource.status

      this.revertedAttrsNameForColumn[keyField] = 'id'
      this.revertedAttrsNameForColumn[planStartDate] = 'baselines.startDate'
      this.revertedAttrsNameForColumn[planEndDate] = 'baselines.endDate'
      this.revertedAttrsNameForColumn[factStartDate] = 'startDate'
      this.revertedAttrsNameForColumn[factEndDate] = 'endDate'
      this.revertedAttrsNameForColumn[name] = 'name'
      this.revertedAttrsNameForColumn[parentId] = 'parentId'
      this.revertedAttrsNameForColumn[percentDone] = 'percentDone'
      this.revertedAttrsNameForColumn[isManuallyScheduled] = 'manuallyScheduled'
      this.revertedAttrsNameForColumn[duration] = 'duration'
      this.revertedAttrsNameForColumn[effort] = 'effort'
      this.revertedAttrsNameForColumn[constraintType] = 'constraintType'
      this.revertedAttrsNameForColumn[constraintDate] = 'constraintDate'
      this.revertedAttrsNameForColumn[inactive] = 'inactive'
      this.revertedAttrsNameForColumn[successors] = 'successors'
      this.revertedAttrsNameForColumn[predecessors] = 'predecessors'
      this.revertedAttrsNameForColumn[status] = 'status'

      const defaultConstraintType = 'startnoearlierthan'

      return data.map(item => {
        let calculatedConstraintType =
          item[constraintType] ? this.constraintType[item[constraintType]] : defaultConstraintType

        return {
          id: item[keyField],
          startDate: DateHelper.parse(item[factStartDate]),
          endDate: DateHelper.parse(item[factEndDate]),
          parentId: item[parentId],
          name: item[name],
          percentDone: item[percentDone],
          baselines: [
            {
              startDate: DateHelper.parse(item[planStartDate]),
              endDate: DateHelper.parse(item[planEndDate])
            }
          ],
          manuallyScheduled: item[isManuallyScheduled],
          duration: item[duration],
          effort: item[effort],
          constraintType: calculatedConstraintType,
          constraintDate: item[constraintDate],
          inactive: item[inactive],
          record_data: item,
          status: item[status]
        }
      })
    },
    getFilters (filters) {
      let model = typeof this.getModel() === 'object' ? this.getModel() : {}
      if (typeof filters !== 'undefined' && filters.length > 0) {
        const builder = new FilterBuilder(
          filters,
          model,
          this.$store,
          EComponentTypes.ganttNew
        )

        return {
          where: {
            and: builder.buildAsApiQl()
          }
        }
      }
      return {}
    },
    async normalizeGanttDataForSaving (
      taskData,
      dependencyData,
      assignmentsData,
      isSameDependencySource = true
    ) {
      let keyField = this.config.taskSource.keyField
      let planStartDate = this.config.taskSource.planStartDate
      let planEndDate = this.config.taskSource.planEndDate
      let factStartDate = this.config.taskSource.factStartDate
      let factEndDate = this.config.taskSource.factEndDate
      let name = this.config.taskSource.nameField
      let parentId = this.config.taskSource.parentIdField
      let percentDone = this.config.taskSource.percentDoneField
      let isManuallyScheduled = this.config.taskSource.isManuallyScheduledField
      let duration = this.config.taskSource.duration
      let effort = this.config.taskSource.effort
      let constraintType = this.config.taskSource.constraintType
      let constraintDate = this.config.taskSource.constraintDate
      let inactive = this.config.taskSource.inactive
      let objectId = this.config.taskSource.entityId
      let indexNumber = this.config.taskSource.indexNumber
      let hierarchyNumber = this.config.taskSource.hierarchyNumber
      let successors = this.config.taskSource.successors
      let predecessors = this.config.taskSource.predecessors
      let status = this.config.taskSource.status

      let dependencyFromAttr = this.config.tasksRelation.fromAttr
      let dependencyToAttr = this.config.tasksRelation.toAttr
      let type = this.config.tasksRelation.type

      let userId = this.$store.getters['Authorization/userId']

      let result = await taskData.map(item => {
        console.log('before normalize', item)
        let id = parseInt(item.id) ? item.id : null
        let parsedParentId = parseInt(item.parentId) ? item.parentId : null
        let normalizedItem = {}
        let calculatedConstraintType =
          this.constraintType.indexOf(item.constraintType) ? this.constraintType.indexOf(item.constraintType) : null

        let calculatedFactStartDate = item.startDate ? new Date(item.startDate).toDateString() : null
        let calculatedEndStartDate = item.endDate ? new Date(item.endDate).toDateString() : null
        let calculatedConstraintDate = item.constraintDate ? new Date(item.constraintDate).toDateString() : null

        if (typeof item.parentId === 'string') {
          this.missingParentIdRecords.push({
            id: item.id,
            parentId: item.parentId,
            wbs: item.wbsCode,
            wbsAttr: hierarchyNumber,
            name: item.name,
            parentName: item.parent.name,
            parentWbs: item.parent.wbsCode,
            parentAttr: parentId,
            objectId: objectId
          })
        }

        normalizedItem[keyField] = id
        normalizedItem[factStartDate] = calculatedFactStartDate
        normalizedItem[factEndDate] = calculatedEndStartDate
        normalizedItem[parentId] = parsedParentId
        normalizedItem[name] = item.name
        normalizedItem[percentDone] = item.percentDone
        normalizedItem[isManuallyScheduled] = item.manuallyScheduled
        normalizedItem[duration] = item.duration
        normalizedItem[effort] = item.effort
        normalizedItem[constraintType] = calculatedConstraintType
        normalizedItem[constraintDate] = calculatedConstraintDate
        normalizedItem[inactive] = item.inactive
        normalizedItem[hierarchyNumber] = item.wbsValue.value
        normalizedItem[indexNumber] = item.sequenceNumber
        normalizedItem['object_id'] = objectId
        normalizedItem[successors] = item.successors.map((item) => {
          return item.to + this.localizedDependencyType[item.type]
        }).join(';')
        normalizedItem[predecessors] = item.predecessors.map((item) => {
          return item.from + this.localizedDependencyType[item.type]
        }).join(';')
        normalizedItem[status] = item.status
        normalizedItem['operation_user_id'] = userId

        if (id === null) {
          this.config.defaultValuesForTask.map(defaultValue => {
            normalizedItem[defaultValue.alias] = this.getModel()[defaultValue.attribute]
          })
        }

        /* if (isSameDependencySource) {
          let dependencies = item.dependencies
          if (dependencies.length > 0) {
            normalizedItem[dependencyFromAttr] = dependencies[0].fromTask?.id ?? null
            normalizedItem[dependencyToAttr] = dependencies[0].toTask?.id ?? null
            normalizedItem[type] = dependencies[0].type ?? null
          } else {
            normalizedItem[dependencyFromAttr] = null
            normalizedItem[dependencyToAttr] = null
            normalizedItem[type] = null
          }
        } */

        return normalizedItem
      })

      if (this.tasksForDelete.length > 0) {
        this.tasksForDelete.map(item => {
          let normalizedItem = {}
          normalizedItem[keyField] = parseInt(item.id) ? item.id : null
          normalizedItem['object_id'] = objectId
          normalizedItem['is_deleted'] = true
          normalizedItem['operation_user_id'] = userId
          result.push(normalizedItem)
        })
      }

      // Подготовка связей задач
      let dependencyKeyField = this.config.tasksRelation.keyField
      let dependencyFromTask = this.config.tasksRelation.fromAttr
      let dependencyToTask = this.config.tasksRelation.toAttr
      let dependencyType = this.config.tasksRelation.type
      let dependencyObjectId = this.config.tasksRelation.entityId

      await dependencyData.map(item => {
        let normalizedItem = {}
        normalizedItem[dependencyKeyField] = parseInt(item.id) ? item.id : null
        normalizedItem[dependencyFromTask] = item.fromTask?.id
        normalizedItem[dependencyToTask] = item.toTask?.id
        // почему +1? Потому что тип связи идет с 0
        // а типы хранящиеся в акценте идут по id и начинаются с 1
        normalizedItem[dependencyType] = item.type + 1
        normalizedItem['object_id'] = dependencyObjectId
        normalizedItem['operation_user_id'] = userId
        result.push(normalizedItem)
      })

      if (this.dependenciesForDelete.length > 0) {
        this.dependenciesForDelete.map(item => {
          let normalizedItem = {}
          normalizedItem[dependencyKeyField] = parseInt(item.id) ? item.id : null
          normalizedItem['object_id'] = dependencyObjectId
          normalizedItem['is_deleted'] = true
          normalizedItem['operation_user_id'] = userId
          result.push(normalizedItem)
        })
      }

      // Подготовка связей ресурсов с задачами
      let assignmentKeyField = this.config.resourceRelations.keyField
      let assignmentTaskField = this.config.resourceRelations.taskId
      let assignmentReferenceField = this.config.resourceRelations.resourceReferenceId
      let assignmentObjectId = this.config.resourceRelations.entityId
      let assignmentUnits = this.config.resourceRelations.units

      await assignmentsData.map(item => {
        let normalizedItem = {}
        normalizedItem[assignmentKeyField] = parseInt(item.id) ? item.id : null
        normalizedItem[assignmentTaskField] = item.eventId
        normalizedItem[assignmentReferenceField] = item.resourceId
        normalizedItem[assignmentUnits] = item.units
        normalizedItem['object_id'] = assignmentObjectId
        normalizedItem['operation_user_id'] = userId
        result.push(normalizedItem)
      })

      if (this.assignmentsForDelete.length > 0) {
        this.assignmentsForDelete.map(item => {
          let normalizedItem = {}
          normalizedItem[assignmentKeyField] = parseInt(item.id) ? item.id : null
          normalizedItem['object_id'] = assignmentObjectId
          normalizedItem['is_deleted'] = true
          normalizedItem['operation_user_id'] = userId
          result.push(normalizedItem)
        })
      }

      return result
    },
    async recordsButchSave (records) {
      await this.$http.post(
        `${this.$config.api}/registryservice/registry/records/bulk_save`,
        {
          records: JSON.stringify(records)
        }
      )

      // временное решение для показа. все из БД будет прилетать при первом сохранении
      if (this.missingParentIdRecords.length > 0) {
        let updatedRecords = await this.getRowData(
          this.config.taskSource.entityId,
          this.config.taskSource.entityType
        )

        let temp = []

        this.missingParentIdRecords.map(item => {
          let recordForUpdate = updatedRecords.find(record => record.record_data[item.wbsAttr] === item.wbs)
          let parent = updatedRecords.find(record => record.record_data[item.wbsAttr] === item.parentWbs)

          if (recordForUpdate && parent) {
            let parentAttr = item.parentAttr
            let tempObject = {}
            tempObject['id'] = recordForUpdate.id
            tempObject[parentAttr] = parent.id
            tempObject['object_id'] = item.objectId
            temp.push(tempObject)
          }
        })

        if (temp.length > 0) {
          await this.$http.post(
            `${this.$config.api}/registryservice/registry/records/bulk_save`,
            {
              records: JSON.stringify(temp)
            }
          )
        }
      }
    },
    async prepareColumnsFromSource (columns) {
      let me = this
      columns.map(async column => {
        let ganttColumn = {
          type: column.ganttColumnType,
          field: this.revertedAttrsNameForColumn[column.value],
          text: column.text,
          name: column.value
        }

        ganttColumn = await this.addPropertiesOnColumnType(ganttColumn, column)

        this.gantt.columns.grid.columns.add(ganttColumn)
      })

      this.gantt.columns.grid.on({
        cellClick: async ({ event, column, record }) => {
          try {
            await ActionExecutor.execute(
              me,
              {
                readonly: me._isReadonly,
                pluginName: me.pluginName,
                action: column.data.accentAction,
                event: event },
              record.record_data
            )
          } catch (error) {
            console.error('Ошибка действия клика на диаграмме Ганта', error)
          }
        }
      })
    },
    async getTaskRelations (entityId, sourceType) {
      let result
      let response

      switch (sourceType) {
        case 'registry':
          response = await this.$http.post(
            `${this.$config.api}/registryservice/registry/${entityId}`,
            null,
            { hideNotification: true }
          )
          result = this.prepareRelations(response.data.data)
          break
      }
    },
    async prepareRelations (dependencies) {
      let fromIdAttr = this.config.tasksRelation.fromAttr
      let toIdAttr = this.config.tasksRelation.toAttr
      let type = this.config.tasksRelation.type
      let keyField = this.config.tasksRelation.keyField

      this.revertedAttrsNameForDependencies[fromIdAttr] = 'fromTask'
      this.revertedAttrsNameForDependencies[toIdAttr] = 'toTask'
      this.revertedAttrsNameForDependencies[type] = 'type'
      this.revertedAttrsNameForDependencies[keyField] = 'id'

      this.dependencies = dependencies.map(dependency => {
        return {
          id: dependency[keyField],
          fromTask: dependency[fromIdAttr],
          toTask: dependency[toIdAttr],
          type: this.dependencyType[dependency[type]]
        }
      })
    },
    async addPropertiesOnColumnType (column, originalColumn) {
      let me = this
      switch (column.type) {
        case 'percentdone':
          column.showCircle = true
          break
        case 'duration':
          // выключает редактирование столбца НЕ СВОЙСТВО readOnly
          // а свойство - editor
          column.editor = false
          break
        case 'html':
          column.type = ''
          column.htmlEncode = false
          column.renderer = function ({ record }) {
            return me.parseHtmlTemplate(originalColumn.htmlTemplate, record)
          }
          break
        case 'resourceassignment':
          column.showAvatars = originalColumn.ganttAvatarShow
          break
      }

      if (!originalColumn.hasOwnProperty('isEdit') || originalColumn.isEdit === false) {
        column.editor = false
      }

      if (originalColumn.hasOwnProperty('action')) {
        column.accentAction = originalColumn.action
      }

      return column
    },
    async disableDiagramEditing () {
      this.gantt.features.dependencies.allowCreate = false
      this.gantt.features.percentBar.allowResize = false
      this.gantt.features.cellEdit.disabled = true
      this.gantt.features.taskEdit.disabled = true
      this.gantt.features.taskMenu.disabled = true
      this.gantt.features.taskDrag.disabled = true
      this.gantt.features.taskResize.disabled = true
      this.gantt.features.taskDragCreate.disabled = true
    },
    async addControlsOnToolbar () {
      this.config.defaultToolbarElements.forEach(toolbarElement => {
        switch (toolbarElement) {
          case 'addTaskButton':
            // Кнопка добавления задачи
            this.gantt.tbar.add({
              type: 'buttonGroup',
              items: [
                {
                  color: 'b-green',
                  ref: 'addTaskButton',
                  icon: 'b-fa b-fa-plus',
                  text: 'Добавить',
                  tooltip: 'Создать новую задачу',
                  onAction: () => {
                    let added = this.gantt.taskStore.rootNode.appendChild(
                      {
                        name: this.gantt.L('New task'),
                        duration: 1,
                        record_data: []
                      }
                    )
                    this.gantt.project.commitAsync()
                    this.gantt.scrollRowIntoView(added)
                  }
                }
              ]
            })
            break
          case 'undoRedo':
            // Кнопки отката действий на диаграмме
            this.gantt.tbar.add({
              ref: 'undoRedo',
              type: 'undoredo',
              items: {
                transactionsCombo: null
              }
            })
            break
          case 'expandAllButton':
            // Кнопки свернуть/развернуть
            this.gantt.tbar.add({
              type: 'buttonGroup',
              items: [
                {
                  ref: 'expandAllButton',
                  icon: 'b-fa b-fa-angle-double-down',
                  tooltip: 'Развернуть все',
                  onAction: () => {
                    this.gantt.expandAll()
                  }
                },
                {
                  ref: 'collapseAllButton',
                  icon: 'b-fa b-fa-angle-double-up',
                  tooltip: 'Свернуть все',
                  onAction: () => {
                    this.gantt.collapseAll()
                  }
                }
              ]
            })
            break
          case 'zoomInOut':
            // Кнопки зума
            this.gantt.tbar.add(
              {
                type: 'buttonGroup',
                items: [
                  {
                    ref: 'zoomInButton',
                    icon: 'b-fa b-fa-search-plus',
                    tooltip: 'Приблизить',
                    onAction: () => {
                      this.gantt.zoomIn()
                    }
                  },
                  {
                    ref: 'zoomOutButton',
                    icon: 'b-fa b-fa-search-minus',
                    tooltip: 'Отдалить',
                    onAction: () => {
                      this.gantt.zoomOut()
                    }
                  },
                  {
                    ref: 'zoomToFitButton',
                    icon: 'b-fa b-fa-compress-arrows-alt',
                    tooltip: 'Масштаб по содержимому',
                    onAction: () => {
                      this.gantt.zoomToFit({
                        leftMargin: 50,
                        rightMargin: 50
                      })
                    }
                  },
                  {
                    ref: 'previousButton',
                    icon: 'b-fa b-fa-angle-left',
                    tooltip: 'Предыдущий интервал',
                    onAction: () => {
                      this.gantt.shiftPrevious()
                    }
                  },
                  {
                    ref: 'nextButton',
                    icon: 'b-fa b-fa-angle-right',
                    tooltip: 'Следующий интервал',
                    onAction: () => {
                      this.gantt.shiftNext()
                    }
                  }
                ]
              })
            break
          case 'projectStartDateField':
            // Дейтпикер старта проекта
            this.gantt.tbar.add({
              type: 'datefield',
              ref: 'startDateField',
              label: 'Старт проекта',
              flex: '0 0 17em',
              onChange: ({ value, oldValue }) => {
                if (value) {
                  this.gantt.startDate = DateHelper.add(value, -1, 'week')
                  this.gantt.project.setStartDate(value)
                }
              }
            })
            break
          case 'importMPPFilePicker':
            // Импорт MPP
            this.gantt.tbar.add([
              {
                type: 'filepicker',
                ref: 'input',
                buttonConfig: {
                  text: 'Выберите файл для импорта (mpp)',
                  icon: 'b-fa-folder-open'
                },
                listeners: {
                  change: ({ files }) => {
                    sendBtn.disabled = files.length === 0
                  },
                  clear: () => {
                    sendBtn.disable()
                  }
                }
              },
              {
                type: 'button',
                ref: 'sendBtn',
                text: 'Импортировать',
                cls: 'b-load-button b-blue',
                icon: 'b-fa-file-import',
                disabled: true,
                onClick: this.onImportButtonClick
              }])
            break
          case 'fullscreen':
            this.gantt.tbar.add({
              type: 'button',
              cls: 'b-icon-fullscreen b-icon',
              onClick: () => {
                if (Fullscreen.isFullscreen) {
                  Fullscreen.exit()
                } else {
                  Fullscreen.request(this.gantt)
                }
              }
            })
            break
        }
      })

      this.gantt.tbar.add([
        // меню настроек
        {
          ref: 'settings',
          text: 'Настройки',
          menu: [
            {
              text: 'Язык',
              menu: [
                {
                  type: 'button',
                  text: 'Russian',
                  onClick: () => {
                    this.gantt.localeManager.locale = ruLocale
                  }
                },
                {
                  type: 'button',
                  text: 'English',
                  onClick: () => {
                    this.gantt.localeManager.locale = engLocale
                  }
                }
              ]
            },
            {
              text: 'Экспорт',
              menu: [
                {
                  type: 'button',
                  text: 'MSP',
                  icon: 'b-fa-file-export',
                  onClick: () => {
                    const filename = this.gantt.project.taskStore.first && `${this.gantt.project.taskStore.first.name}.xml`
                    this.gantt.features.mspExport.export({
                      filename
                    })
                  }
                },
                {
                  type: 'button',
                  text: 'Excel',
                  ref: 'excelExportBtn',
                  icon: 'b-fa-file-export',
                  onAction: () => {
                    const filename = this.gantt.project.taskStore.first && this.gantt.project.taskStore.first.name
                    this.gantt.features.excelExporter.export({
                      filename
                    })
                  }
                }
              ]
            },
            {
              text: 'Критические пути',
              checked: false,
              onToggle: () => {
                this.gantt.features.criticalPaths.disabled = !this.gantt.features.criticalPaths.disabled
              }
            },
            {
              text: 'Линии проекта',
              checked: true,
              onToggle: () => {
                this.gantt.features.projectLines.disabled = !this.gantt.features.projectLines.disabled
              }
            },
            {
              text: 'Исходные планы',
              checked: false,
              onToggle: () => {
                this.gantt.features.baselines.disabled = !this.gantt.features.baselines.disabled
              }
            },
            {
              text: 'Линия прогресса',
              checked: false,
              onToggle: () => {
                this.gantt.features.progressLine.disabled = !this.gantt.features.progressLine.disabled
              }
            },
            {
              text: 'Показать линии столбцов',
              checked: true,
              onToggle: () => {
                this.gantt.features.columnLines.disabled = !this.gantt.features.columnLines.disabled
              }
            },
            {
              text: 'Показать родительские области',
              checked: false,
              onToggle: () => {
                this.gantt.features.parentArea.disabled = !this.gantt.features.parentArea.disabled
              }
            },
            {
              text: 'Показать зависимости',
              checked: true,
              onToggle: () => {
                this.gantt.features.dependencies.disabled = !this.gantt.features.dependencies.disabled
              }
            },
            {
              text: 'Показать названия',
              checked: false,
              onToggle: () => {
                this.gantt.features.labels.disabled = !this.gantt.features.labels.disabled
              }
            },
            {
              text: 'Спрятать календарь',
              cls: 'b-separator',
              checked: false,
              onToggle: () => {
                this.gantt.subGrids.normal.collapsed = !this.gantt.subGrids.normal.collapsed
              }
            }
          ]
        }
        // фильтр статический (по конкретному столбцу)
        /*{
          type: 'textfield',
          ref: 'filterByName',
          cls: 'filter-by-name',
          flex: '0 0 13.5em',
          placeholder: 'Найти по названию',
          clearable: true,
          keyStrokeChangeDelay: 100,
          triggers: {
            filter: {
              align: 'end',
              cls: 'b-fa b-fa-filter'
            }
          },
          onChange: ({ value }) => {
            if (value === '') {
              this.gantt.taskStore.clearFilters()
            } else {
              value = value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
              this.gantt.taskStore.filter({
                filters: task => task.name && task.name.match(new RegExp(value, 'i')),
                replace: true
              })
            }
          }
        }*/
      ])

      if (this.config.isDiagramEditing) {
        this.gantt.tbar.add(
          // сохранить изменения по всем строкам
          {
            type: 'button',
            text: 'Сохранить',
            onClick: async () => {
              await this.saveTasksAndRecords()
            }
          })
      } else {
        this.gantt.tbar.add(
          {
            type: 'button',
            text: 'Сохранение недоступно',
            cls: 'b-transparent'
          })
      }
    },
    async onImportButtonClick () {
      const { files } = input

      if (files) {
        const formData = new FormData()
        formData.append('request', files[0])
        this.gantt.maskBody('Импортирование проекта ...')
        this.$http.post(`${this.$config.api}/xsd2jsonconverter/convert/mpp`, formData)
          .then(async response => {
            if (response.data.success && response.data.parameters) {
              const { project } = this.gantt
              let data = JSON.parse(response.data.parameters)

              await this.importData(data)

              project.destroy()

              this.gantt.setStartDate(this.gantt.project.startDate)
              await this.gantt.scrollToDate(this.gantt.project.startDate, { block: 'start' })

              input.clear()
              this.gantt.unmaskBody()

              Toast.show('Импорт успешно выполнен!')
            } else {
              Toast.show({
                html: `Ошибка импорта: ${response.data.message}`,
                color: 'b-red',
                style: 'color:white',
                timeout: 3000
              })
            }
          }).catch(response => {
            Toast.show({
              html: `Ошибка импорта: ${response.error || response.message}`,
              color: 'b-red',
              style: 'color:white',
              timeout: 3000
            })
          })
      }
    },
    async importData (data) {
      let me = this
      let project = new this.gantt.projectModelClass({
        silenceInitialCommit: false
      })

      this.gantt.project = project
      this.gantt.calendarManager = project.calendarManagerStore
      this.gantt.taskStore = project.taskStore
      this.gantt.assignmentStore = project.assignmentStore
      this.gantt.resourceStore = project.resourceStore
      this.gantt.dependencyStore = project.dependencyStore

      Object.assign(this.gantt, {
        calendarMap: {},
        resourceMap: {},
        taskMap: {}
      })

      me.importCalendars(data)

      const tasks = me.getTaskTree(Array.isArray(data.tasks) ? data.tasks : [data.tasks])

      me.importResources(data)
      me.importAssignments(data)

      this.gantt.taskStore.rootNode.appendChild(tasks[0].children)

      me.importDependencies(data)

      me.importProject(data)

      // Assign the new project to the gantt before launching commitAsync()
      // to let Gantt resolve possible scheduling conflicts
      this.gantt.project = project

      await this.gantt.project.commitAsync()

      me.importColumns(data)

      return project
    },
    // начало функций импорта mpp файлов
    importResources (data) {
      this.gantt.resourceStore.add(data.resources.map(this.processResource, this.gantt))
    },
    processResource (data) {
      const { id } = data
      delete data.id
      data.calendar = this.gantt.calendarMap[data.calendar]
      const resource = new this.gantt.resourceStore.modelClass(data)
      this.gantt.resourceMap[id] = resource
      return resource
    },
    importDependencies (data) {
      this.gantt.dependencyStore.add(data.dependencies.map(this.processDependency, this.gantt))
    },
    processDependency (data) {
      const me = this.gantt
      const { fromEvent, toEvent } = data
      delete data.id
      const dep = new me.dependencyStore.modelClass(data)
      dep.fromEvent = me.taskMap[fromEvent].id
      dep.toEvent = me.taskMap[toEvent].id
      return dep
    },
    importAssignments (data) {
      this.gantt.assignmentStore.add(data.assignments.map(this.processAssignment, this.gantt))
    },
    processAssignment (data) {
      const me = this.gantt
      delete data.id
      return new me.assignmentStore.modelClass({
        units: data.units,
        event: me.taskMap[data.event],
        resource: me.resourceMap[data.resource]
      })
    },
    getTaskTree (tasks) {
      return tasks.map(this.processTask, this.gantt)
    },
    processTask (data) {
      const me = this.gantt
      const { id, children } = data

      delete data.children
      delete data.id
      delete data.milestone

      data.calendar = me.calendarMap[data.calendar]

      const t = new me.taskStore.modelClass(data)

      if (children) {
        t.appendChild(this.getTaskTree(children))
      }

      t._id = id
      me.taskMap[t._id] = t
      return t
    },
    processCalendarChildren (children) {
      return children.map(this.processCalendar, this.gantt)
    },
    processCalendar (data) {
      const me = this.gantt
      const { id, children } = data
      const intervals = data.intervals

      delete data.children
      delete data.id

      const t = new me.calendarManager.modelClass(Object.assign(data, { intervals }))

      if (children) {
        t.appendChild(this.processCalendarChildren(children))
      }

      t._id = id
      me.calendarMap[t._id] = t

      return t
    },
    importCalendars (data) {
      this.gantt.calendarManager.add(this.processCalendarChildren(data.calendars.children))
    },
    importColumns (data) {
      let columns = data.columns.map(this.processColumn, this).filter(column => column)
      const columnStore = this.gantt.subGrids.locked.columns

      if (!columns.length && this.gantt.defaultColumns) {
        columns = [
          { type: 'name', field: 'name', width: 250 },
          { type: 'addnew' }
        ]
      }

      if (columns.length) {
        columnStore.removeAll(true)
        columnStore.add(columns)
      }
    },
    processColumn (data) {
      const columnClass = this.gantt.columns.constructor.getColumnClass(data.type)

      // ignore unknown columns (or columns that classes are not loaded)
      if (columnClass) {
        return Object.assign({ region: 'locked' }, data)
      }
    },
    importProject (data) {
      if ('calendar' in data.project) {
        data.project.calendar = this.gantt.calendarMap[data.project.calendar]
      }
      Object.assign(this.gantt.project, data.project)
    },
    // конец функций импорта mpp файлов
    async addDefaultColumns () {
      this.gantt.subGrids.locked.columns.removeAll(true)

      if (this.config.defaultColumns.length === 0) return

      let columns = this.config.defaultColumns.map(item => {
        return {
          type: item
        }
      })

      this.gantt.subGrids.locked.columns.add(columns)
    },
    parseHtmlTemplate (currentValue, record) {
      if (currentValue && record) {
        let attributes = currentValue.match(/\{{(.*?)\}}/g) || []
        attributes.forEach((attribute) => {
          attribute = attribute.replace('{{', '').replace('}}', '')
          let value
          if (attribute.indexOf(':date') !== -1) {
            value = record.record_data[attribute.replace(':date', '')]
            if (value) {
              value = this.$moment(new Date(value)).format('DD.MM.Y')
            }
          } else {
            if (typeof record.record_data !== 'undefined') {
              value = record.record_data[attribute]
              try {
                value = JSON.parse(value)
              } catch (e) {}
              if (value instanceof Array) {
                value = value.map(item => item.name).join(',')
              }
            }
          }
          currentValue = currentValue.replace(`{{${attribute}}}`, value ?? '')
        })

        return currentValue
      }
    },
    async saveTasksAndRecords () {
      let data = await this.normalizeGanttDataForSaving(
        this.gantt.taskStore.allRecords,
        this.gantt.dependencyStore.allRecords,
        this.gantt.assignmentStore.allRecords
      )
      await this.recordsButchSave(data)
    },
    async getStatusesByXrefValue () {
      let statusXrefId = parseInt(this.config.taskSource.status.replace(/[^0-9]/g, ''))

      let statusResponse = await this.$http.get(
        `${this.$config.api}/objecteditor/entities/${statusXrefId}`,
        { hideNotification: true }
      )

      let targetStatusXrefId = statusResponse.data.data.properties.find((item) => item.id === 'xref').value

      let registryStatusResponse = await this.$http.get(
        `${this.$config.api}/objecteditor/entities/${targetStatusXrefId}`,
        { hideNotification: true }
      )

      let registryStatusId = registryStatusResponse.data.data.object_id

      let records = await this.$http.post(
        `${this.$config.api}/registryservice/registry/${registryStatusId}`,
        null,
        { hideNotification: true }
      )
      records = records.data.data

      this.features.taskEdit.items.generalTab.items.statusField.items = records.map(item => {
        return {
          value: item.id,
          text: item[`attr_${targetStatusXrefId}_`]
        }
      })
    },
    async getResources (entityId, sourceType, filters, isResourceRelation) {
      let result
      let response
      let buildFilters = this.getFilters(filters)
      let tempResult

      switch (sourceType) {
        case 'query':
          response = await this.$http.post(
            `${this.$config.api}/datawarehouseservice/query/${entityId}`,
            typeof buildFilters.where !== 'undefined' ? buildFilters : null,
            { hideNotification: true }
          )
          tempResult = response.data
          break
        case 'registry':
          response = await this.$http.post(
            `${this.$config.api}/registryservice/registry/${entityId}`,
            typeof buildFilters.where !== 'undefined' ? buildFilters : null,
            { hideNotification: true }
          )
          tempResult = response.data.data
          break
      }

      if (isResourceRelation) {
        result = await this.prepareResourceRelations(tempResult)
      } else {
        result = await this.prepareResources(tempResult)
      }

      return result
    },
    async prepareResources (resources) {
      let keyField = this.config.resources.keyField
      let name = this.config.resources.name
      let imageUrl = this.config.resources.imageUrl

      return resources.map(resource => {
        return {
          id: resource[keyField],
          name: resource[name],
          imageUrl: resource[imageUrl]
        }
      })
    },
    async prepareResourceRelations (resourceRelations) {
      let keyField = this.config.resourceRelations.keyField
      let taskField = this.config.resourceRelations.taskId
      let resourceField = this.config.resourceRelations.resourceReferenceId
      let units = this.config.resourceRelations.units

      return resourceRelations.map(resourceRelation => {
        return {
          id: resourceRelation[keyField],
          event: resourceRelation[taskField],
          resource: resourceRelation[resourceField],
          units: resourceRelation[units]
        }
      })
    }
  }
}
</script>

<style scoped>
@import "~@bryntum/gantt/gantt.stockholm.css";
</style>
